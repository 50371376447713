import React from "react";
import "./Home.css";
import Header from "../components/home/Header";
import Content from "../components/home/Content";

export default function Home() {
  return (
    <div>
      <Header />
      <Content />
    </div>
  );
}
